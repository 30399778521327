<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-form label-width='68px'>
          <el-row :gutter='15'>
            <el-col :span='8'>
              <el-card class='panel'>
                <div slot='header'>
                  <span>刊例类型（{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style='float: right; padding: 3px 0' type='text' @click="saveSetting('type')"
                             :disabled='loading'
                             :loading='loading'>保存
                  </el-button>
                </div>
                <div>
                  <el-row>
                    <el-col :span='24'>
                      <el-radio-group v-model='formData.type'>
                        <el-radio :label='item.val' :value='item.val' v-for='(item,index) in typeMap' :key='index'>
                          {{ item.title }}<span>刊例</span>
                        </el-radio>
                      </el-radio-group>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
              <el-card class='panel'>
                <div slot='header'>
                  <span>刊例表格-工作薄配置</span>
                  <el-button style='float: right; padding: 3px 0' type='text' @click='saveLogoSetting'
                             :disabled='loading'
                             :loading='loading'>保存
                  </el-button>
                </div>
                <div>
                  <h3>平台Logo设置</h3>
                  <el-form :model='logoSetting' inline>
                    <div>
                      <el-form-item label=''>
                        单元格：第
                        <el-input placeholder='Logo图标摆放列，例如：E' v-model='logoSetting.cellCol' clearable
                                  style='width: 80px' />
                        列</el-form-item>
                      <el-form-item label=''>
                        第
                        <el-input placeholder='Logo图标摆放行，例如：1' v-model='logoSetting.cellRow' clearable
                                  style='width: 80px' />
                        行
                      </el-form-item>
                    </div>

                    <div>
                      <el-form-item label=''>
                        偏移：  X轴
                        <el-input placeholder='X轴偏移量' v-model='logoSetting.offsetX' clearable style='width: 80px' />
                        磅
                      </el-form-item>
                      <el-form-item label=''>
                        Y轴
                        <el-input placeholder='Y轴偏移量' v-model='logoSetting.offsetY' clearable style='width: 80px' />
                        磅
                      </el-form-item>
                    </div>
                    <div>

                      <el-form-item label=''>
                        大小：宽度
                        <el-input placeholder='宽度' v-model='logoSetting.width' clearable style='width: 80px' />
                        磅
                      </el-form-item>
                      <el-form-item label=''>
                        高度
                        <el-input placeholder='高度' v-model='logoSetting.height' clearable style='width: 80px' />
                        磅
                      </el-form-item>
                    </div>



                  </el-form>
                </div>
              </el-card>
              <el-card class='panel'>
                <div slot='header'>
                  <span>刊例表格-表头设置</span>
                  <el-button style='float: right; padding: 3px 0' type='text' @click='saveTableHeader'
                             :disabled='loading'
                             :loading='loading'>保存
                  </el-button>
                </div>
                <div>
                  <el-row>
                    <el-col :span='24'>
                      <el-form-item label='背景色'>
                        <el-color-picker
                          v-model='formData.bg_color'
                          show-alpha
                          :predefine='predefineColors'>
                        </el-color-picker>
                      </el-form-item>
                      <el-form-item label='字颜色'>
                        <el-color-picker
                          v-model='formData.font_color'
                          show-alpha
                          :predefine='predefineColors'>
                        </el-color-picker>
                      </el-form-item>
                      <el-form-item label='行高'>
                        <el-input type='text' :placeholder='`表格行高(单位默认：pt(points 磅值),推荐：${header_height}`'
                                  v-model='formData.header_height' clearable />
                        <p style='font-size: 0.8em;'>
                          Valid units are pt (points 磅值), px (pixels), pc (pica), in (inches), cm (centimeters) and mm
                          (millimeters).
                        </p>
                        <el-link @click='setHeaderHeight'>推荐行高：{{ header_height }}</el-link>
                      </el-form-item>

                    </el-col>
                  </el-row>
                </div>
              </el-card>
              <el-card class='panel'>
                <div slot='header'>
                  <span>第一部分-常规字段</span>
                  <el-button style='float: right; padding: 3px 0' type='text' @click="saveSetting('common_fields')"
                             :disabled='loading'
                             :loading='loading'>保存
                  </el-button>
                </div>
                <div>
                  <el-checkbox-group v-model='formData.common_fields'>
                    <el-checkbox @change='forceUpdate(extra_col)' :label='extra_col.field'
                                 :value='extra_col.field'
                                 v-for='(extra_col,index) in common_cols' :key='index'>
                      <span :title='extra_col.field'>{{ extra_col.title }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-card>
              <el-card class='panel'>
                <div slot='header'>
                  <span>第二部分-平台报价字段</span>
                  <el-button style='float: right; padding: 3px 0' type='text' @click="saveSetting('platform_quote')"
                             :disabled='loading'
                             :loading='loading'>保存
                  </el-button>
                </div>
                <div>
                  <el-tabs v-model='activePriceName' type='card'>
                    <el-tab-pane label='视频时长报价' name='single' v-if='priceShowMap.single'>
                      <div class='box-shadow' style='padding: 10px;'>
                        <div>
                          <h3>视频时长报价（一般是线上平台的刊例）</h3>
                          <div style='display: flex;flex-direction: column;gap: 5px;'>
                            <div>
                              <el-table class='tbl_quote'
                                        row-key='field'
                                        ref='tbl_quote'
                                        :data='formData.platform_quote'
                                        border
                              >
                                <el-table-column label='报价字段' prop='field' min-width='100'></el-table-column>
                                <el-table-column label='报价标题' prop='title' min-width='140'>
                                  <template slot-scope='scope'>
                                    <p v-html='scope.row.title'></p>
                                  </template>
                                </el-table-column>
                                <el-table-column label='操作' align='center' width='140'>
                                  <template slot-scope='scope'>
                                    <el-button type='text' @click='editRow(scope.row,scope.$index)'><i
                                      class='el-icon-edit'></i>编辑
                                    </el-button>
                                    <el-button type='text' @click='delRow(scope.row,scope.$index)'><i
                                      class='el-icon-remove'></i>删除
                                    </el-button>
                                  </template>
                                </el-table-column>
                              </el-table>
                            </div>
                            <div class='form-card'>
                              <el-form :inline='false' :model='formQuote' label-width='90px'>
                                <el-form-item label='报价字段'>
                                  <el-input v-model='formQuote.field'></el-input>
                                </el-form-item>
                                <el-form-item label='报价标题'>
                                  <el-input type='textarea' rows='2' v-model='formQuote.title'></el-input>
                                </el-form-item>
                                <el-form-item label='占位提示'>
                                  <el-input v-model='formQuote.placeholder'></el-input>
                                </el-form-item>
                              </el-form>
                              <div>
                                <el-button @click='addRow' type='primary' v-if='currentIndex===-1'>添加</el-button>
                                <el-button @click='saveRow' type='primary' v-else>保存</el-button>
                                <el-button @click='resetRow' type='info'>重置</el-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='demo-cards' style='height: 400px;overflow-y: scroll'>
                          <div v-for='(item,platform_code) in quoteMap' :key='platform_code'
                               style='border-radius: 20px;margin-bottom: 20px' class='demo-card'>
                            <el-link @click='selectDemo(item.cols)' class='demo-select'>{{ platform_code }}<i
                              class='el-icon-connection'></i>
                            </el-link>
                            <el-table :data='item.cols'>
                              <el-table-column label='报价字段' prop='field'></el-table-column>
                              <el-table-column label='报价标题' prop='title'></el-table-column>
                            </el-table>
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label='品类场次报价' name='multi' v-if='priceShowMap.multi'>
                      <div class='box-shadow'>
                        <el-table :data='quoteMulti.cols' border>
                          <el-table-column label='标题' prop='title' align='center'></el-table-column>
                          <el-table-column label='字段' prop='field' align='center'></el-table-column>
                          <el-table-column label='说明' prop='placeholder' width='200'></el-table-column>
                          <el-table-column label='录入方式' prop='type_alias'></el-table-column>
                          <el-table-column label='选项' prop='options'>
                            <template slot-scope='{row}'>
                              <div v-if='row.options'>
                            <span v-for='(option) in row.options' :key='option.value'>
                              {{ option.label || '' }}
                            </span>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label='专（混）场报价' name='mixed' v-if='priceShowMap.mixed'>
                      <div v-if='quoteMixed.sri'>
                        <h4>{{ quoteMixed.sri.label || '' }}</h4>
                        <div>
                          编辑方式： {{ quoteMixed.sri.type == 'richTxt' ? '富文本编辑器' : quoteMixed.sri.type }}
                        </div>
                      </div>
                      <div v-if='quoteMixed.mfr'>
                        <h4>{{ quoteMixed.mfr.label || '' }}</h4>
                        <div>
                          编辑方式： {{ quoteMixed.mfr.type == 'richTxt' ? '富文本编辑器' : quoteMixed.mfr.type }}}
                        </div>
                      </div>
                      <div v-if='quoteMixed.pfc'>
                        <h4>{{ quoteMixed.pfc.label || '' }}</h4>
                        <div>
                          编辑方式： {{ quoteMixed.pfc.type == 'table' ? '表格' : quoteMixed.pfc.type }}
                        </div>
                        <div style='padding: 10px'>
                          <el-table :data='quoteMixed.pfc.cols' v-if='quoteMixed.pfc.cols' border>
                            <el-table-column label='标题' prop='title' align='center'></el-table-column>
                            <el-table-column label='字段' prop='field' align='center'></el-table-column>
                            <el-table-column label='说明' prop='placeholder' width='200'></el-table-column>
                            <el-table-column label='录入方式' prop='type_alias'></el-table-column>
                            <el-table-column label='选项' prop='options'>
                              <template slot-scope='{row}'>
                                <div v-if='row.options'>
                            <span v-for='(option) in row.options' :key='option.value'>
                              {{ option.label || '' }}
                            </span>
                                </div>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>

                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-card>
            </el-col>
            <el-col :span='16'>
<!--              <el-card class='panel'>-->
<!--                <div slot='header'>-->
<!--                  <span>第三部分-其他字段（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>-->
<!--                  <el-button style='float: right; padding: 3px 0' type='text' @click="saveSetting('extra_fields')"-->
<!--                             :disabled='loading'-->
<!--                             :loading='loading'>保存-->
<!--                  </el-button>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <el-row>-->
<!--                    <el-col :span='24'>-->
<!--                      <el-checkbox-group v-model='formData.extra_fields'>-->
<!--                        <el-checkbox @change='forceUpdate' :label='extra_col.field' :value='extra_col.field'-->
<!--                                     v-for='(extra_col,index) in extra_cols' :key='index'>{{ extra_col.title }}-->
<!--                        </el-checkbox>-->
<!--                      </el-checkbox-group>-->
<!--                    </el-col>-->
<!--                  </el-row>-->
<!--                </div>-->
<!--              </el-card>-->

              <div class='panel border-effect' style='padding: 10px'>
                <div>
                  <div class='flex-sb'>
                    <div>
                      <span> 刊例表格列（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>
                      &nbsp;
                      &nbsp;
                      <el-link type='warning' icon='el-icon-refresh' @click='handleResetExcel'>重算</el-link>
                      &nbsp;
                      &nbsp;
                      <el-link type='danger' icon='el-icon-refresh-left' @click='handleCleanExcel'>清空</el-link>
                      &nbsp;
                      &nbsp;
                      <el-link v-if='this.excelColsArr.length>0' icon='el-icon-bottom' type='primary' size='mini' @click='handleAllToExcelCols'>
                        全部载入
                      </el-link>
                    </div>
                    <el-button style='float: right; padding: 3px 0' type='text' @click="saveSetting('columns_data')"
                               :disabled='loading'
                               :loading='loading'>保存
                    </el-button>

                  </div>

                </div>
                <div>
                  <div style='padding: 5px'>
                    <el-tag v-for='(tag,index) in excelColsArr'
                            :key='`${tag.field}_${index}`'
                            effect='plain' style='margin-right: 5px;margin-bottom: 5px' @close='handleCloseRange(tag,index)' closable>
                      {{index+1}}、{{ tag.title }}
                    </el-tag>

                  </div>
                  <div class='border-effect' style='padding: 10px'>
                    <PlcColumnsTable :table-data.sync='formData.columns_data' @delRow='handleDelPlcColsRow'
                                     @handleSave="saveSetting('columns_data')"></PlcColumnsTable>
                  </div>

                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>

      </div>
    </PageHeaderLayout>
    <PlcFieldDialog :visible.sync='dialogVisible'>

    </PlcFieldDialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { scrollTo } from '@/utils/scroll-to'
import EditableCell from '@/components/EditableCell'
import PlcFieldDialog from '@/pages/platform/components/PlcFieldDialog'
import Sortable from 'sortablejs'
import PlcColumnsTable from '@/pages/platform/PlcColumnsTable'
import plcMap from '@/json/plc.json'

const { deepClone } = require('@/utils')

export default {
  name: 'PlcSet',
  components: {
    PlcColumnsTable,
    PageHeaderLayout, EditableCell, PlcFieldDialog
  },
  computed: {
    quoteMulti() {
      return this.quoteInfo.multi[this.formData.type] || []
    },
    quoteMixed() {
      return this.quoteInfo.mixed || {}
    },
    priceShowMap() {
      if (this.formData.type && this.customPriceMap) {
        return this.customPriceMap[this.formData.type] || {
          'multi': true,
          'single': true,
          'mixed': true,
          'showLast': true
        }
      } else
        return {
          'multi': true,
          'single': true,
          'mixed': true,
          'showLast': true
        }
    }
  },
  watch: {
    'formData.type': {
      handler(val) {
        let priceMap = this.customPriceMap[val]
        delete priceMap.showLast

        if (!priceMap[this.activePriceName]) {
          //遍历
          Object.keys(priceMap).some((key) => {
            if (priceMap[key]) {
              this.activePriceName = key
              return false
            }
          })
        }
      }
    }
  },
  data() {
    return {
      platform_id: null,
      platform: {},
      loading: false,
      activeName: 'checkbox',
      activePriceName: 'single',
      header_height: '31.5pt',//表格行高
      formData: {
        type: 'ONLINE',
        bg_color: '#000000',
        font_color: '#FFFFFF',
        header_height: '31.5pt',//表格行高
        setting: {
          quote: {
            price_type_map: this.priceTypeMap,
            setting: { multi: this.quoteMulti, single: [], mixed: this.quoteMixed }
          },
          common_fields: [],//常规字段
          platform_quote: [],//平台报价字段
          extra_fields: [],//其他字段
          columns_data: []//列自定义数据
        },//全部配置
        common_fields: [],//常规字段
        platform_quote: [],//平台报价字段
        extra_fields: [],//其他字段
        columns_data: [],//列自定义数据
        work_sheet: {
          logoSetting: {
            cellCol: 'E',
            cellRow: 1,
            width: 50,
            height: 50,
            offsetX: 10,
            offsetY: 10
          }
        }
      },
      excelColsArr: [],
      logoSetting: {
        cellCol: 'E',
        cellRow: 1,
        width: 50,
        height: 50,
        offsetX: 10,
        offsetY: 10
      },
      defaultLogSetting: {
        cellCol: 'E',
        cellRow: 1,
        width: 50,
        height: 50,
        offsetX: 10,
        offsetY: 10
      },
      typeMap: plcMap.typeMap,
      common_cols: plcMap.common_cols,
      extra_cols: plcMap.extra_cols,
      multiChecked: false,
      singleChecked: false,
      priceTypeMap: {
        'multi': {
          'title': '品类场次报价',
          'checked': true
        },
        'single': {
          'title': '报价类目报价',
          'checked': true
        },
        'mixed': {
          'title': '专（混）场报价',
          'checked': true
        }
      },
      customPriceMap: plcMap.customPriceMap,
      quoteInfo: plcMap.quoteInfo,
      extra_fields: [],
      formQuote: { title: '', field: 'quote_', placeholder: '请输入下月预估价格' },
      currentIndex: -1,
      quoteMap: {},
      predefineColors: [
        'rgba(0,0,0,1)',
        'rgba(255,0,59,1)',
        'rgba(255,182,0,1)',
        'rgba(0,158,239,1)',
        'rgba(255,78,0,1)',
        'rgba(179,0,0,1)',
        'rgba(255,255,250,1)',
        'rgba(255,255,200,1)'
      ],
      columns: [],//表格列表配置
      //excel表格配置
      excelDataList: [],
      dialogVisible: false
    }
  },
  methods: {
    insertColumn(item, rangeArr) {
      let index = this.formData.columns_data ? this.formData.columns_data.findIndex(value => value.field === item.field) : -1

      // let rowOrg = this.formData.columns_data[index];
      let row = {
        field: item.field,
        title: item.title,
        height: '80.1',
        width: '15',
        horizontal: 'center',
        showTool: true,
        children: item.children
      }
      // Object.assign(row, rowOrg)//合并数据
      if (rangeArr.indexOf(item.field) > -1 && index === -1)
        this.formData.columns_data.push(row)
    },
    calcColumns() {
      this.common_cols.forEach((item) => {
        this.insertColumn(item, this.formData.common_fields)
      })
      //报价字段
      this.formData.platform_quote.forEach((item) => {

        this.insertColumn(item, [item.field])
      })
      //额外字段
      this.extra_cols.forEach((item) => {
        this.insertColumn(item, this.formData.extra_fields)
      })
    },
    delRow(row, index) {
      this.formData.platform_quote.splice(index, 1)
      // this.calcColumns()
    },
    // eslint-disable-next-line no-unused-vars
    editRow(row, index) {
      this.formQuote = deepClone(row)
      this.currentIndex = index
      // this.calcColumns()
    },
    saveRow() {
      this.formData.platform_quote[this.currentIndex]['field'] = this.formQuote.field
      this.formData.platform_quote[this.currentIndex]['title'] = this.formQuote.title
      this.formData.platform_quote[this.currentIndex]['placeholder'] = this.formQuote.placeholder

      this.resetRow()
      // this.calcColumns()
    },
    addRow() {
      this.formData.platform_quote.push({ ...deepClone(this.formQuote) })
      this.resetRow()
      // this.calcColumns()
    },
    resetRow() {
      this.currentIndex = -1
      let index = this.formData.platform_quote ? this.formData.platform_quote.length + 1 : 1
      this.formQuote = { title: '', field: `quote_${index}` }
    },
    /**
     * 获取报价配置
     * @returns {Promise<void>}
     */
    async getSetting() {
      let { quote_setting } = await this.$api.getPublicationSetting({ quote_type: 'platform_quote' })
      this.quoteMap = quote_setting
    },
    selectDemo(rows) {
      //选用案例
      this.formData.platform_quote = deepClone(rows)
      // this.calcColumns()
      scrollTo(0, 800)
    },
    //复选框勾选后触发
    forceUpdate() {
      // this.calcColumns()//重新计算表格列
      this.$forceUpdate()
    },
    async savePlcSetting() {
      await this.saveSetting('platform_quote')
    },
    async saveExtra() {
      await this.saveSetting('extra_fields')
    },
    async saveTableHeader() {
      await this.saveSetting(['bg_color', 'font_color', 'header_height'])
    },
    async saveLogoSetting() {
      let data = {
        platform_id: this.platform_id,
        work_sheet: { logoSetting: this.logoSetting }
      }
      this.loading = true
      let info = await this.$api.savePlatformSetting(data)
      if (info) {
        this.$notify.success('保存成功')
      }
      this.loading = false
    },
    //保存配置
    async handleSave() {

      const setting = {
        quote: {
          type_map: this.priceTypeMap,
          setting: { multi: this.quoteMulti, single: this.formData.platform_quote, mixed: this.quoteInfo.mixed }
        },
        common_fields: this.formData.common_fields,
        // platform_quote: this.formData.platform_quote,
        extra_fields: this.formData.extra_fields,
        columns_data: this.formData.columns_data,
        work_sheet: {
          logo: this.logoSetting
        }
      }
      this.loading = true
      let info = await this.$api.savePlatformSetting({
        platform_id: this.platform_id,
        setting: setting
      })
      if (info) {
        this.$notify.success('保存成功')
      }
      this.loading = false
    },
    async saveSetting(field) {
      let data = {
        platform_id: this.platform_id
      }
      if (typeof field === 'string')
        data[field] = this.formData[field]
      else if (typeof field === 'object') {
        field.forEach((f) => {
          data[f] = this.formData[f]
        })
      }

      this.loading = true
      let info = await this.$api.savePlatformSetting(data)
      if (info) {
        await this.handleSave()
      }
      this.loading = false

      //重算 刊例表格可设置列范围
      this.showExcelFieldRange()
    },
    async getPlatformInfo() {
      let { info } = await this.$api.getPlatformInfo(this.platform_id)
      if (info) {
        this.platform = info
      }
    },
    reset() {
      this.formData = {
        type: 'ONLINE',
        bg_color: '#000000',
        font_color: '#FFFFFF',
        header_height: '31.5pt',//表格行高
        //全部配置
        setting: {
          quote: { price_type_map: this.priceTypeMap, setting: { multi: this.quoteMulti, single: [] } },
          common_fields: [],//常规字段
          platform_quote: [],//平台报价字段
          extra_fields: [],//其他字段
          columns_data: []//列自定义数据
        },
        common_fields: [],//常规字段
        platform_quote: [],//平台报价字段
        extra_fields: [],//其他字段
        columns_data: [],//列自定义数据
        work_sheet: {
          logoSetting: { ...this.defaultLogSetting }
        }
      }
      // this.formData = {
      //   platform_quote: [],
      //   extra_fields: [],
      //   common_fields: [],
      //   columns_data: [],
      //   bg_color: 'rgba(0, 0, 0,1)',
      //   font_color: 'rgba(255, 255, 255, 1)',
      //   type: 'ONLINE'
      // }
      this.resetRow()
    },
    async getPlatformSettingInfoByPlatformId() {
      this.reset()
      let { info } = await this.$api.getPlatformSettingInfoByPlatformId(this.platform_id)
      if (info && info.id) {
        this.formData = info || {}
        const lastSetting = info.setting || {}
        //数据类型要保证正确，否则可能渲染失败（MAC电脑上）(数据类型不对，确保数据类型正确，手动处理)
        this.formData.platform_quote = info.platform_quote || []
        this.formData.setting = lastSetting
        this.formData.extra_fields = info.extra_fields || []
        this.formData.common_fields = info.common_fields || []
        this.formData.columns_data = info.columns_data || []
        this.formData.work_sheet = info.work_sheet || {}
        this.formData.type = info.type || 'ONLINE'
        if (info.work_sheet)
          this.logoSetting = info.work_sheet.logoSetting || this.defaultLogSetting

        //显示上次的报价配置
        if (lastSetting.quote) {
          if (lastSetting.quote.type_map) {
            this.priceTypeMap.multi.checked = !!(lastSetting.quote.type_map.multi.checked)
            this.priceTypeMap.single.checked = !!(lastSetting.quote.type_map.single.checked)
          }
        }


        if (this.formData.common_fields) {
          this.toggleSelection()
        }
        this.resetRow()
        // this.calcColumns()
        this.rowDrop()
      }
    },
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector('.tbl_quote > .el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.formData.platform_quote.splice(oldIndex, 1)[0]
          _this.formData.platform_quote.splice(newIndex, 0, currRow)
        }
      })
    },
    setHeaderHeight() {
      this.formData.header_height = this.header_height
    },
    // eslint-disable-next-line no-unused-vars
    changeCommonFields(rows) {

      // this.formData.common_fields = rows.map((item) => {
      //   return item.field
      // })
    },
    // 渲染选中
    toggleSelection() {
      const data = this.common_cols ? this.common_cols : []
      this.$nextTick(function() {
        data.forEach(row => {
          if (this.formData.common_fields.findIndex(value => value === row.field) > -1) {
            if (this.$refs['tblCommonCol'])
              this.$refs['tblCommonCol'].toggleRowSelection(row, true)
          }
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    handleDelPlcColsRow(row, index) {
      const pIndex = this.formData.columns_data.indexOf(row)
      if (pIndex > -1) {
        // console.log('pIndex', pIndex)
        // if (pIndex !== index) {
        //   this.$notify.warning('索引异常')
        //   return false
        // }
        this.formData.columns_data.splice(pIndex, 1)
        this.showExcelFieldRange()
      } else {

        this.$notify.warning('未匹配到该数据项，无法移除')
      }
    },
    handleResetExcel() {
      this.$confirm('根据当前刊例表格-标题设置重新生成excel导出列配置', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(async () => {
        this.formData.columns_data = []
        const cols = [...this.common_cols, ...this.extra_fields]
        const rangeArr = this.formData.common_fields || []
        cols.forEach((item) => {
          // this.insertColumn(item, cols)
          if (rangeArr.indexOf(item.field) > -1) {
            this.formData.columns_data.push(
              {
                field: item.field,
                title: item.title,
                height: '80.1',
                width: '15',
                horizontal: 'center',
                showTool: true,
                children: item.children || []
              }
            )
            rangeArr.push(item.field)
          }
        })
        this.showExcelFieldRange()

      }).catch(() => {
        this.$notify.info('已取消重置')
      })
    },
    handleAllToExcelCols(){
      this.excelColsArr.forEach((item)=>{
        this.handleAddToExcelCol(item)
      })
      this.showExcelFieldRange()
    },
    handleCloseRange(item,posIndex){
      this.handleAddToExcelCol(item)
      this.excelColsArr.splice(posIndex,1)
    },
    handleAddToExcelCol(item){
      let itemChildren = []
      if (item.field === 'multi') {
        // 计算 children
        itemChildren = this.getMultiChildren()
      }
      const cell = {
        field: item.field,
        title: item.title,
        height: '80.1',
        width: '15',
        horizontal: 'center',
        merge_col: 'Y',
        merge_row: 'Y',
        showTool: true,
        children: itemChildren
      }
      this.formData.columns_data.push(cell)
    },
    getMultiChildren() {
      // 平台报价字段(multi)
      return this.quoteMulti.cols
    },
    showExcelFieldRange() {
      const cols = [...this.common_cols, ...this.extra_fields]
      const rangeArr = this.formData.common_fields || []
      const checkedCols = this.formData.columns_data.map((item) => {
        return item.field
      })

      this.excelColsArr = []
      cols.forEach((item) => {
        // this.insertColumn(item, cols)
        if (rangeArr.indexOf(item.field) > -1 && checkedCols.indexOf(item.field) === -1) {
          this.excelColsArr.push(item)
        }
      })

    },
    handleCleanExcel() {
      this.formData.columns_data = []
      this.showExcelFieldRange()
    }
  },
  mounted() {
    this.platform_id = this.$route.params ? this.$route.params.platform_id : null
    if (this.platform_id) {
      this.getPlatformInfo()
      this.getSetting()
      this.getPlatformSettingInfoByPlatformId()
    }

  }
}
</script>

<style scoped>
.panel {
  margin-top: 10px;
}

.demo-cards {
  padding: 20px 40px;
  background-color: #DCDFE6;
}

.demo-select {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 800;
  color: #ff3176;
}

.demo-card {
  border-radius: 10px;
}

.form-card {
  border: #DCDFE6 1px solid;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 10px;
}
</style>
